import SEO from '@/components/seo'
import Wrapper from '@/components/wrapper'
import useStore from '@/hooks/useStore'
import { getData, getStrapiImage } from '@/lib/api'
import type { NextPage, GetStaticProps } from 'next'
import dynamic from 'next/dynamic'
import React from 'react'

const Banner = dynamic(import('@/components/banner'))
const Value = dynamic(import('@/components/value'))
const HomeAboutUs = dynamic(import('@/components/aboutUs/components/Home'))
const RegisterCta = dynamic(import('@/components/registerCta'))
const HomeBlog = dynamic(import('@/components/blog/components/Home'))

interface Props {
  home: Home
  menu: Menu
  company: Company
}

const Home: NextPage<Props> = ({ home, menu, company }) => {
  const setHome = useStore((state) => state.setHome)
  const setMenu = useStore((state) => state.setMenu)
  const setCompany = useStore((state) => state.setCompany)

  React.useEffect(() => {
    setHome(home)
    setMenu(menu)
    setCompany(company)
  }, [])

  return (
    <Wrapper>
      <SEO
        title="Accueil | UPG"
        description={company?.description}
        name={company?.name}
        logo={getStrapiImage(company?.logo)}
        favicon={getStrapiImage(company?.favicon)}
        image={getStrapiImage(company?.ogimage)}
        phone={company?.phone}
        GA_MEASUREMENT_ID="0000000000"
      />
      <Banner />
      <Value />
      <HomeAboutUs />
      <RegisterCta />
      <HomeBlog />
    </Wrapper>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const company = await getData(`/company?populate=deep`)
  const menu = await getData(`/menu?populate=deep`)
  const home = await getData(`/home?populate=deep`)

  return {
    props: {
      company: company?.data,
      menu: menu?.data,
      home: home?.data,
    },
    revalidate: 1,
  }
}

export default Home
